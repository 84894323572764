<script setup lang="ts">
import { useRoute } from "vue-router";
import type { NavItemProps } from "../protocols";

const props = defineProps<NavItemProps>();

const route = useRoute();

const isLinkActive = (to: string) => {
  if (to !== "/painel") {
    return route.path.includes(to);
  }
  return route.path === to;
};
</script>

<template>
  <li class="py-1">
    <button
      class="z-50 w-full flex justify-start align-items start-0 gap-x-28 cursor-pointer border-2"
      :class="{
        'rounded-lg border-gray-700 bg-gray-850': isLinkActive(item.to),
        'border-transparent': !isLinkActive(item.to),
      }"
      @click="props.select(props.item.label)"
    >
      <NuxtLink
        :to="props.item.to"
        class="flex items-center text-gray-100 text-sm py-2.5 px-5 h-full w-full"
        :class="{ 'text-primary-300': isLinkActive(item.to) }"
      >
        <div v-if="props.item.icon">
          <Icon
            v-if="props.item.icon"
            :name="props.item.icon"
            class="mr-2"
            :class="{ 'text-primary-300': isLinkActive(item.to) }"
            size="18"
          />
        </div>
        <div v-if="props.item.img">
          <img
            v-show="isLinkActive(item.to)"
            preload
            :src="props.item.imgActive"
            width="16"
            height="16"
            class="mr-3"
          />
          <img
            v-show="!isLinkActive(item.to)"
            preload
            :src="props.item.img"
            width="16"
            height="16"
            class="mr-3"
          />
        </div>
        <span>{{ props.item.label }}</span>
      </NuxtLink>
    </button>
  </li>
</template>

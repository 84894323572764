<script setup lang="ts">
import Sidebar from "@/components/private/Sidebar";
import Header from "@/components/private/Header";
import Footer from "@/components/private/Footer";

useHead({
  bodyAttrs: {
    class:
      "flex flex-auto flex-col h-full w-full bg-white-10 relative bg-woodsmoke",
  },
});
</script>

<template>
  <div class="flex flex-col min-h-screen">
    <Header />
    <div class="flex-shrink-0 flex-grow-0 flex-1 max-xl:hidden">
      <Sidebar />
    </div>

    <div class="flex flex-auto max-w-full xl:pl-[200px] text-white">
      <div class="flex flex-auto flex-col w-full">
        <main class="pt-20 pb-16 flex-grow">
          <div class="w-full ml-auto mr-auto block">
            <suspense>
              <div>
                <slot></slot>
              </div>
            </suspense>
          </div>
        </main>
      </div>
    </div>
    <div class="max-w-full xl:pl-[200px] mt-auto">
      <Footer />
    </div>
  </div>
</template>

<style>
#__nuxt {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
</style>

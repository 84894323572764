import { type NavItemDraw } from "./protocols";

export const usePrivateNavItems = (): Ref<NavItemDraw[]> => {
  const { $tt } = useNuxtApp();

  return computed(() => {
    return [
      {
        label: $tt("private.nav.start"),
        to: "/painel",
        code: "root",
        icon: "tdesign:home",
      },
      {
        label: $tt("private.nav.wallet"),
        to: "/painel/wallet",
        code: "wallet",
        img: "/short-logo-hurst-white.svg",
        imgActive: "/short-logo-hurst.svg",
      },
      {
        label: $tt("private.nav.pending"),
        to: "/painel/pendentes",
        code: "pending",
        icon: "ph:target-bold",
      },
      {
        label: $tt("private.nav.help"),
        to: "/painel/ajuda",
        code: "help",
        icon: "bx:user",
      },
    ];
  });
};

<script setup lang="ts">
import NavItem from "./components/SidebarNavItem.vue";
import SidebarBrand from "./components/SidebarBrand.vue";
import SidebarNav from "./components/SidebarNav.vue";
import { usePrivateNavItems } from "./_nav";

const activeMenu = ref("Início");

const items = usePrivateNavItems();

function selectMenu(menu: string) {
  activeMenu.value = menu;
}
</script>
<template>
  <div
    class="overflow-y-auto h-full flex-col fixed top-0 bg-[#171c20] w-[200px] z-50 border-r-2 border-[#374148]"
  >
    <div class="self-center px-10 py-[36px] mb-4 justify-center items-center">
      <SidebarBrand src="/logo-hurst-branco.svg" to="/" />
    </div>
    <SidebarNav>
      <NavItem
        v-for="item in items"
        :key="item.code"
        :item="item"
        :active="activeMenu"
        :select="selectMenu"
      />
    </SidebarNav>
  </div>
</template>

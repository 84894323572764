<script setup lang="ts">
import { type AppDownloadButtonProps } from "./protocols";

const props = defineProps<AppDownloadButtonProps>();
</script>
<template>
  <a
    :href="props.url"
    target="_blank"
    class="flex items-center justify-center border border-[#9ba9b0] bg-[#1f282e] px-[6px] py-1 rounded-lg font-poppins"
  >
    <slot></slot>
    <div class="flex flex-col ml-3">
      <span class="text-[6px]"> Dowload via </span>
      <span class="text-[10px]"> {{ props.store }}</span>
    </div>
  </a>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";
import {
  appStoreUrl,
  playStoreUrl,
  hurstLinkedinUrl,
  hurstInstagramUrl,
  hurstTwitterUrl,
  hurstYoutubeUrl,
} from "@/utils/constants";

import AppDownloadButton from "@/components/private/AppDownloadButton";
import SocialMediaLinkIcon from "@/components/private/SocialMediaLinkIcon";

const { $tt } = useNuxtApp();
</script>

<template>
  <footer class="text-[#6e7579] w-full ml-auto mr-auto block pl-6 pr-6 mb-8">
    <div class="sm:flex items-center mb-7">
      <div class="max-sm:w-full max-sm:justify-center max-sm:inline-flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="70"
          height="13"
          viewBox="0 0 70 13"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M53.7678 9.42246C53.7678 8.90672 53.5078 8.11896 51.8269 7.87538L51.8244 7.87429C47.3029 7.21742 46.6984 5.32531 46.6984 4.09361C46.6984 3.49136 46.8277 2.90057 47.0999 2.44292C47.8151 1.24079 49.3526 0.312988 52.1772 0.312988C53.8722 0.312988 55.0543 0.703473 55.7663 1.04497V2.99163C54.4664 2.25741 53.4092 2.08093 52.5313 2.08093C51.088 2.08093 50.2363 2.89141 50.2363 3.57902C50.2363 4.09469 50.4969 4.88251 52.1785 5.12617C56.7 5.78304 57.3045 7.67501 57.3045 8.90678C57.3045 9.50897 57.1751 10.0998 56.903 10.5574C56.1877 11.7596 54.6496 12.6874 51.8256 12.6874C49.4327 12.6874 48.0572 11.9087 47.5904 11.5877L48.225 10.003C49.5306 10.743 50.5917 10.9195 51.4728 10.9195C52.9174 10.9195 53.7678 10.1089 53.7678 9.42246ZM24.0407 8.44458C24.0407 10.0041 23.4783 10.9195 21.9198 10.9195C20.3614 10.9195 19.799 10.0041 19.799 8.44458V0.490557H16.2642V8.09168C16.2642 11.2097 18.8017 12.6874 21.921 12.6874C25.0402 12.6874 27.5776 11.2097 27.5776 8.09168V0.490557H24.0429V8.44458H24.0407ZM63.6362 0.490557H59.3933V2.25856H62.9293V12.5109H66.464V2.25856V2.25734H70V0.490557H63.6362ZM38.5358 7.56122H36.0609V2.25734H38.5358C39.6091 2.25734 40.3038 3.64049 40.3038 4.9087C40.3038 6.17685 39.6091 7.56 38.5358 7.56V7.56122ZM43.8386 4.9087C43.8386 2.46795 42.0366 0.489469 39.5956 0.489469H32.5249V12.5098H36.0609V9.32794H39.0424L40.3448 12.5098H43.1316L41.6198 8.81566C42.969 8.07459 43.8386 6.60377 43.8386 4.9087ZM3.53587 7.56122H7.77863V0.490557H11.3134V12.5109H7.77863V9.32909H3.53587V12.5109H0V0.490557H3.53587V7.56122Z"
            fill="#B6C1C9"
          />
        </svg>
      </div>
      <div class="flex max-sm:w-full justify-center max-sm:mt-5">
        <div class="ml-5">
          <AppDownloadButton :url="appStoreUrl" store="Apple Store">
            <Icon icon="ic:baseline-apple" width="24" height="24" />
          </AppDownloadButton>
        </div>

        <div class="ml-[10px]">
          <AppDownloadButton :url="playStoreUrl" store="Google Play">
            <Icon icon="bxl:play-store" width="24" height="24" />
          </AppDownloadButton>
        </div>
      </div>

      <ul
        class="sm:ml-auto inline-flex gap-x-6 justify-center max-sm:w-full max-sm:mt-5"
      >
        <SocialMediaLinkIcon :url="hurstLinkedinUrl">
          <Icon icon="ri:linkedin-fill" width="24" height="24" />
        </SocialMediaLinkIcon>
        <SocialMediaLinkIcon :url="hurstInstagramUrl">
          <Icon icon="mdi:instagram" width="24" height="24" />
        </SocialMediaLinkIcon>
        <SocialMediaLinkIcon :url="hurstYoutubeUrl">
          <Icon icon="mdi:youtube" width="24" height="24" />
        </SocialMediaLinkIcon>
        <SocialMediaLinkIcon :url="hurstTwitterUrl">
          <Icon icon="ri:twitter-x-fill" width="24" height="24" />
        </SocialMediaLinkIcon>
      </ul>
    </div>
    <p class="text-[10px] font-nunito max-sm:text-center">
      {{ $tt("private.footer.content.0") }}
    </p>
    <p class="text-[8px] font-nunito max-sm:text-center">
      {{ $tt("private.footer.content.1") }}
    </p>
  </footer>
</template>

import { useGqlFullName } from "~/graphql/repo";

export const useFullName = () => {
  const gqlFullName = useGqlFullName();

  return useHurstQuery({
    queryKey: ["USER-FULL-NAME"],
    async queryFn() {
      const res = await gqlFullName();
      return res.user.fullName;
    },
    staleTime: 60 * 60 * 1000,
  });
};

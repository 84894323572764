<script setup lang="ts">
import { type SocialMediaLinkIconProps } from "./protocols";
const props = defineProps<SocialMediaLinkIconProps>();
</script>

<template>
  <NuxtLink :to="props.url" target="_blank">
    <slot></slot>
  </NuxtLink>
</template>

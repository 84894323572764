<script setup lang="ts">
import { type AvatarProps } from "./protocols";

const props = defineProps<AvatarProps>();
</script>
<template>
  <img
    v-if="!props.initials"
    class="w-9 h-9 rounded-full ring-2 ring-primary-400"
    :src="props.url"
    alt="Avatar do usuário"
    :style="{ width: `${props.width}px`, height: `${props.height}px` }"
  />

  <NuxtLink
    v-if="props.initials"
    class="relative inline-flex items-center justify-center w-9 h-9 overflow-hidden bg-gray-100 rounded-full text-center"
    :to="props.url"
    :style="{
      width: `${props.width}px`,
      height: `${props.height}px`,
      fontSize: `${props.textSize}px`,
    }"
  >
    <span class="font-medium text-gray-600">{{ props.initials }}</span>
  </NuxtLink>
</template>
